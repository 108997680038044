import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./global.css";
import FrontLayout from "./components/FrontLayout";
import Login from "./components/login";
import Chatbot from "./components/chatbot";
import TaskApp from "./components/task";
import ConfigApp from "./components/config";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/" element={<FrontLayout />}>
          <Route path="" element={<Chatbot />}></Route>
          <Route path="taskconfig" element={<TaskApp />}></Route>
          <Route path="config" element={<ConfigApp />}></Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
