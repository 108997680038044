import React, { useState, useEffect } from "react";
import {
  createStyles,
  Input,
  Select,
  Textarea,
  Radio,
  Group,
  Switch,
  Button,
} from "@mantine/core";
import { useForm, isNotEmpty, isEmail, hasLength } from "@mantine/form";
import { DataTable } from "mantine-datatable";
import { Modal } from "react-responsive-modal";

const hostinfo = "https://ai-agent.smhc.tw:8000";

const UseRandomStyles = createStyles((theme) => ({
  table: {
    // backgroundColor: "rgb(55 65 81 / var(--tw-bg-opacity))",
  },
}));

const PAGE_SIZE = 10;

const TaskApp = () => {
  const [page, setPage] = useState(1);
  const [tasks, setTasks] = useState([]);
  const [records, setRecords] = useState(tasks.slice(0, PAGE_SIZE));
  const [height, setHeight] = useState(window.innerHeight);

  const [open, setOpen] = useState(false);
  const [outputFormat, setOutputFormat] = useState("");
  const [latency, setLatency] = useState("");
  const [method, setMethod] = useState("");
  const [method1, setMethod1] = useState("");
  const [isnew, setNew] = useState(false);
  const [disable, setDisable] = useState(false);
  const [state, setState] = useState({
    name: "",
    source: {
      type: "",
      content: "",
      path: "",
      endpoint: "",
      method: "",
      connection: { database: "", user: "", password: "", host: "" },
      table: "",
    },
    prompt: "",
    output_format: "",
    latency: "",
    report_dest: {
      type: "",
      email: "",
      path: "",
      connection: { database: "", user: "", password: "", host: "" },
      table: "",
      endpoint: "",
      method: "",
    },
    status: false,
  });

  const OnOpenModal = (task) => {
    console.log(task);
    if (!task["source"]["connection"]) task["source"]["connection"] = {};
    if (!task["report_dest"]["connection"])
      task["report_dest"]["connection"] = {};
    setState(task);
    setNew(false);
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  const closeIcon = (
    <svg fill="currentColor" viewBox="0 0 20 20" width={28} height={28}>
      <path
        fillRule="evenodd"
        d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  const { classes, cx } = UseRandomStyles();

  const getAllTasks = async () => {
    try {
      const apiUrl = `${hostinfo}/api/alltasks`;
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      const response = await fetch(apiUrl, requestOptions);
      const { tasks } = await response.json();
      let tasks_array = JSON.parse(tasks);
      tasks_array = tasks_array.map((task, index) => {
        return { id: index + 1, ...task };
      });
      console.log(tasks_array);
      const from = (page - 1) * PAGE_SIZE;
      const to = from + PAGE_SIZE;
      setRecords(tasks_array.slice(from, to));
      setTasks(tasks_array);
    } catch (e) {
      console.log(e);
    }
  };

  const submit_data = async (e) => {
    setDisable(true);
    try {
      const apiUrl = `${hostinfo}/api/updatetask`;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ task: state, isnew: isnew }),
      };
      const response = await fetch(apiUrl, requestOptions);
      const { _id } = await response.json();
      getAllTasks();
      if (_id == "failure") alert("Failure!");
      else alert("Success!");
    } catch (e) {
      alert("Server Error occured!");
      console.log(e);
    }
    setDisable(false);
    setOpen(false);
  };

  const newTask = () => {
    setState({
      name: "",
      source: {
        type: "",
        content: "",
        path: "",
        endpoint: "",
        method: "",
        connection: { database: "", user: "", password: "", host: "" },
        table: "",
      },
      prompt: "",
      output_format: "",
      latency: "",
      report_dest: {
        type: "",
        email: "",
        path: "",
        connection: { database: "", user: "", password: "", host: "" },
        table: "",
        endpoint: "",
        method: "",
      },
      status: false,
    });
    setNew(true);
    setOpen(true);
  };

  useEffect(() => {
    getAllTasks();
    window.addEventListener("resize", function () {
      setHeight(window.innerHeight);
    });
  }, []);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(tasks.slice(from, to));
  }, [page]);

  return (
    <div className="px-8 py-16">
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        closeIcon={closeIcon}
        style={{ borderRadius: "30px" }}
      >
        <div
          style={{
            height: "500px",
            width: "600px",
            borderRadius: "30px",
            margin: "0px 40px",
          }}
        >
          任務配置
          <hr />
          <div
            className="pt-4"
            style={{
              overflow: "scroll",
              margin: "5px 0px",
              height: "450px",
            }}
          >
            <b>Task Name</b>
            <Input
              placeholder="Task Name"
              style={{ marginBottom: "5px" }}
              value={state.name}
              onChange={(e) => setState({ ...state, name: e.target.value })}
            />
            <b>Output Format</b>
            <Select
              placeholder="Output_format"
              searchable
              onSearchChange={setOutputFormat}
              searchValue={outputFormat}
              value={state.output_format}
              onChange={(value) => setState({ ...state, output_format: value })}
              nothingFound="No options"
              data={["pdf", "docx", "txt", "html"]}
              style={{ marginBottom: "5px" }}
              styles={(theme) => ({
                item: {
                  // applies styles to selected item
                  "&[data-selected]": {
                    "&, &:hover": {
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.teal[9]
                          : theme.colors.teal[1],
                      color:
                        theme.colorScheme === "dark"
                          ? theme.white
                          : theme.colors.teal[9],
                    },
                  },

                  // applies styles to hovered item (with mouse or keyboard)
                  "&[data-hovered]": {},
                },
              })}
            />
            <b>Execution Time</b>
            <Select
              placeholder="Execution Time"
              searchable
              onSearchChange={setLatency}
              searchValue={latency}
              value={state.latency}
              onChange={(value) => setState({ ...state, latency: value })}
              nothingFound="No options"
              data={["immediate", "daily", "weekly", "monthly"]}
              style={{ marginBottom: "5px" }}
              styles={(theme) => ({
                item: {
                  // applies styles to selected item
                  "&[data-selected]": {
                    "&, &:hover": {
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.teal[9]
                          : theme.colors.teal[1],
                      color:
                        theme.colorScheme === "dark"
                          ? theme.white
                          : theme.colors.teal[9],
                    },
                  },

                  // applies styles to hovered item (with mouse or keyboard)
                  "&[data-hovered]": {},
                },
              })}
            />
            <Textarea
              label="Prompt"
              placeholder="Write the prompt..."
              autosize
              minRows={2}
              maxRows={4}
              value={state.prompt}
              onChange={(e) => setState({ ...state, prompt: e.target.value })}
              style={{ marginBottom: "5px" }}
            />
            <Radio.Group
              name="source"
              label="Source Config"
              description="Select the source type for reporting"
              withAsterisk
              value={state.source.type}
              onChange={(value) =>
                setState({ ...state, source: { ...state.source, type: value } })
              }
              style={{ marginBottom: "5px", marginTop: "5px" }}
            >
              <Group mt="xs">
                <Radio value="api" label="API" />
                <Radio value="pdf" label="PDF" />
                <Radio value="csv" label="CSV" />
                <Radio value="txt" label="TXT" />
                <Radio value="database" label="Database Connection" />
                <Radio value="freetext" label="Free Text" />
              </Group>
            </Radio.Group>
            {(state.source.type == "pdf" ||
              state.source.type == "csv" ||
              state.source.type == "txt") && (
              <Input
                placeholder="File Path"
                style={{ marginBottom: "5px" }}
                value={state.source.path}
                onChange={(e) =>
                  setState({
                    ...state,
                    source: { ...state.source, path: e.target.value },
                  })
                }
              />
            )}
            {state.source.type == "freetext" && (
              <Textarea
                label="Content"
                placeholder="Write the content..."
                autosize
                minRows={2}
                maxRows={4}
                value={state.source.content}
                onChange={(e) =>
                  setState({
                    ...state,
                    source: { ...state.source, content: e.target.value },
                  })
                }
                style={{ marginBottom: "5px" }}
              />
            )}
            {state.source.type == "api" && (
              <React.Fragment>
                Endpoint
                <Input
                  placeholder="Endpoint"
                  style={{ marginBottom: "5px" }}
                  value={state.source.endpoint}
                  onChange={(e) =>
                    setState({
                      ...state,
                      source: { ...state.source, endpoint: e.target.value },
                    })
                  }
                />
                Method
                <Select
                  placeholder="Method"
                  searchable
                  onSearchChange={setMethod}
                  searchValue={method}
                  value={state.source.method}
                  onChange={(value) =>
                    setState({
                      ...state,
                      source: { ...state.source, method: value },
                    })
                  }
                  nothingFound="No options"
                  data={["GET", "POST"]}
                  style={{ marginBottom: "5px" }}
                  styles={(theme) => ({
                    item: {
                      // applies styles to selected item
                      "&[data-selected]": {
                        "&, &:hover": {
                          backgroundColor:
                            theme.colorScheme === "dark"
                              ? theme.colors.teal[9]
                              : theme.colors.teal[1],
                          color:
                            theme.colorScheme === "dark"
                              ? theme.white
                              : theme.colors.teal[9],
                        },
                      },

                      // applies styles to hovered item (with mouse or keyboard)
                      "&[data-hovered]": {},
                    },
                  })}
                />
              </React.Fragment>
            )}
            {state.source.type == "database" && (
              <React.Fragment>
                Database Name
                <Input
                  placeholder="Database Name"
                  style={{ marginBottom: "5px" }}
                  value={state.source.connection["database"]}
                  onChange={(e) =>
                    setState({
                      ...state,
                      source: {
                        ...state.source,
                        connection: {
                          ...state.source.connection,
                          database: e.target.value,
                        },
                      },
                    })
                  }
                />
                Database User
                <Input
                  placeholder="User"
                  style={{ marginBottom: "5px" }}
                  value={state.source.connection.user}
                  onChange={(e) =>
                    setState({
                      ...state,
                      source: {
                        ...state.source,
                        connection: {
                          ...state.source.connection,
                          user: e.target.value,
                        },
                      },
                    })
                  }
                />
                Database Password
                <Input
                  placeholder="Password"
                  style={{ marginBottom: "5px" }}
                  value={state.source.connection.password}
                  onChange={(e) =>
                    setState({
                      ...state,
                      source: {
                        ...state.source,
                        connection: {
                          ...state.source.connection,
                          password: e.target.value,
                        },
                      },
                    })
                  }
                />
                Database Host
                <Input
                  placeholder="Host"
                  style={{ marginBottom: "5px" }}
                  value={state.source.connection.host}
                  onChange={(e) =>
                    setState({
                      ...state,
                      source: {
                        ...state.source,
                        connection: {
                          ...state.source.connection,
                          host: e.target.value,
                        },
                      },
                    })
                  }
                />
                Database Table Name
                <Input
                  placeholder="Table Name"
                  style={{ marginBottom: "5px" }}
                  value={state.source.table}
                  onChange={(e) =>
                    setState({
                      ...state,
                      source: {
                        ...state.source,
                        table: e.target.value,
                      },
                    })
                  }
                />
              </React.Fragment>
            )}
            <Radio.Group
              name="destination"
              label="Destination Config"
              description="Select the destination type for reporting"
              withAsterisk
              value={state.report_dest.type}
              onChange={(value) =>
                setState({
                  ...state,
                  report_dest: { ...state.report_dest, type: value },
                })
              }
              style={{ marginBottom: "5px", marginTop: "5px" }}
            >
              <Group mt="xs">
                <Radio value="database" label="Database Connection" />
                <Radio value="api" label="API" />
                <Radio value="email" label="E-mail" />
                <Radio value="file" label="File Directory" />
              </Group>
            </Radio.Group>
            {state.report_dest.type == "email" && (
              <React.Fragment>
                E-mail
                <Input
                  placeholder="Email"
                  style={{ marginBottom: "5px" }}
                  value={state.report_dest.email}
                  onChange={(e) =>
                    setState({
                      ...state,
                      report_dest: {
                        ...state.report_dest,
                        email: e.target.value,
                      },
                    })
                  }
                />
              </React.Fragment>
            )}
            {state.report_dest.type == "database" && (
              <React.Fragment>
                Database Name
                <Input
                  placeholder="Database Name"
                  style={{ marginBottom: "5px" }}
                  value={state.report_dest.connection["database"]}
                  onChange={(e) =>
                    setState({
                      ...state,
                      report_dest: {
                        ...state.report_dest,
                        connection: {
                          ...state.report_dest.connection,
                          database: e.target.value,
                        },
                      },
                    })
                  }
                />
                Database User
                <Input
                  placeholder="User"
                  style={{ marginBottom: "5px" }}
                  value={state.report_dest.connection.user}
                  onChange={(e) =>
                    setState({
                      ...state,
                      report_dest: {
                        ...state.report_dest,
                        connection: {
                          ...state.report_dest.connection,
                          user: e.target.value,
                        },
                      },
                    })
                  }
                />
                Database Password
                <Input
                  placeholder="Password"
                  style={{ marginBottom: "5px" }}
                  value={state.report_dest.connection.password}
                  onChange={(e) =>
                    setState({
                      ...state,
                      report_dest: {
                        ...state.report_dest,
                        connection: {
                          ...state.report_dest.connection,
                          password: e.target.value,
                        },
                      },
                    })
                  }
                />
                Database Host
                <Input
                  placeholder="Host"
                  style={{ marginBottom: "5px" }}
                  value={state.report_dest.connection.host}
                  onChange={(e) =>
                    setState({
                      ...state,
                      report_dest: {
                        ...state.report_dest,
                        connection: {
                          ...state.report_dest.connection,
                          host: e.target.value,
                        },
                      },
                    })
                  }
                />
                Database Table Name
                <Input
                  placeholder="Table Name"
                  style={{ marginBottom: "5px" }}
                  value={state.report_dest.table}
                  onChange={(e) =>
                    setState({
                      ...state,
                      report_dest: {
                        ...state.report_dest,
                        table: e.target.value,
                      },
                    })
                  }
                />
              </React.Fragment>
            )}

            {state.report_dest.type == "file" && (
              <React.Fragment>
                File Path
                <Input
                  placeholder="File Path"
                  style={{ marginBottom: "5px" }}
                  value={state.report_dest.path}
                  onChange={(e) =>
                    setState({
                      ...state,
                      report_dest: { ...state.report_dest, path: e.target.value },
                    })
                  }
                />
              </React.Fragment>
            )}

            {state.report_dest.type == "api" && (
              <React.Fragment>
                Endpoint
                <Input
                  placeholder="Endpoint"
                  style={{ marginBottom: "5px" }}
                  value={state.report_dest.endpoint}
                  onChange={(e) =>
                    setState({
                      ...state,
                      report_dest: {
                        ...state.report_dest,
                        endpoint: e.target.value,
                      },
                    })
                  }
                />
                Method
                <Select
                  placeholder="Method"
                  searchable
                  onSearchChange={setMethod1}
                  searchValue={method1}
                  value={state.report_dest.method}
                  onChange={(value) =>
                    setState({
                      ...state,
                      report_dest: { ...state.report_dest, method: value },
                    })
                  }
                  nothingFound="No options"
                  data={["GET", "POST"]}
                  style={{ marginBottom: "5px" }}
                  styles={(theme) => ({
                    item: {
                      // applies styles to selected item
                      "&[data-selected]": {
                        "&, &:hover": {
                          backgroundColor:
                            theme.colorScheme === "dark"
                              ? theme.colors.teal[9]
                              : theme.colors.teal[1],
                          color:
                            theme.colorScheme === "dark"
                              ? theme.white
                              : theme.colors.teal[9],
                        },
                      },

                      // applies styles to hovered item (with mouse or keyboard)
                      "&[data-hovered]": {},
                    },
                  })}
                />
              </React.Fragment>
            )}
            <Switch
              onLabel="Active"
              offLabel="Inactive"
              size="lg"
              checked={state.status}
              style={{ marginBottom: "5px" }}
              onChange={(e) =>
                setState({ ...state, status: e.currentTarget.checked })
              }
            />
            <button
              className="btn-primary"
              onClick={submit_data}
              disabled={disable}
            >
              {!disable ? "Submit" : "Sending..."}
            </button>
          </div>
        </div>
      </Modal>
      <div
        style={{
          marginBottom: "5px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div></div>
        <Button onClick={newTask}>+New</Button>
      </div>
      <DataTable
        withBorder
        borderRadius="md"
        borderColor="#ccc"
        withColumnBorders
        striped
        highlightOnHover
        className={classes.table}
        height={height - 110}
        // provide data
        records={records}
        columns={[
          {
            accessor: "id",
            title: "Task Id",
            textAlignment: "center",
            cellsClassName: classes.table,
            titleClassName: classes.table,
          },
          {
            accessor: "name",
            title: "Task Name",
            textAlignment: "center",
            cellsClassName: classes.table,
            titleClassName: classes.table,
          },
          {
            accessor: "prompt",
            title: "Prompt",
            textAlignment: "center",
            cellsClassName: classes.table,
            titleClassName: classes.table,
          },
          {
            accessor: "source",
            title: "Source Type",
            textAlignment: "center",
            cellsClassName: classes.table,
            titleClassName: classes.table,
            render: ({ source }) => {
              return (
                <div className="flex justify-center">
                  <img src={`/img/${source["type"]}.png`} width="25" />
                </div>
              );
            },
          },
          {
            accessor: "report",
            title: "Destination Type",
            textAlignment: "center",
            cellsClassName: classes.table,
            titleClassName: classes.table,
            render: ({ report_dest }) => {
              return (
                <div className="flex justify-center">
                  <img src={`/img/${report_dest["type"]}.png`} width="25" />
                </div>
              );
            },
          },
          {
            accessor: "latency",
            title: "Execution time",
            textAlignment: "center",
            cellsClassName: classes.table,
            titleClassName: classes.table,
            render: ({ latency }) => {
              return (
                <div className="flex justify-center">
                  <img src={`/img/${latency}.png`} width="25" />
                </div>
              );
            },
          },

          {
            accessor: "status",
            title: "Status",
            textAlignment: "center",
            cellsClassName: classes.table,
            titleClassName: classes.table,
            render: ({ status }) => {
              let activeStatus = "inactive";
              if (status) activeStatus = "active";
              return (
                <div className="flex justify-center">
                  <img src={`/img/${activeStatus}.png`} width="25" />
                </div>
              );
            },
          },
        ]}
        onRowClick={(task) => OnOpenModal(task)}
        totalRecords={tasks.length}
        recordsPerPage={PAGE_SIZE}
        page={page}
        onPageChange={(p) => setPage(p)}
        paginationSize="sm"
        paginationColor="grape"
        loadingText="加载中..."
      />
    </div>
  );
};

export default TaskApp;
