import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./chat/sidebar";
import {
  ConvoStackWrapper,
  ConvoStackWidget,
} from "@convostack/frontend-react";

export default function FrontLayout() {
  // const graphqlUrl = "http://185.209.223.61:8001/graphql";
  // const websocketUrl = "ws://185.209.223.61:8001/graphql";

  const navigate = useNavigate();

  useEffect(() => {
    const userDataObj = localStorage.getItem("userData");
    if (!userDataObj) {
      navigate("/login");
    } else {
      try {
        const userData = JSON.parse(userDataObj);
        if (
          userData.userName !== "admin" ||
          userData.password !== "GenAIBPM2023"
        ) {
          navigate("/login");
        }
      } catch (error) {
        console.error("Error parsing userData:", error);
        navigate("/login");
      }
    }
  }, []);

  return (
    <>
      <div className="w-full h-full">
        <dh-component>
          <div className="flex flex-no-wrap h-screen">
            <Sidebar />
            <div className="w-full py-10 bg-gray-200 h-screen">
              <Outlet />
            </div>
          </div>
        </dh-component>
        {/* <ConvoStackWrapper>
          <ConvoStackWidget
            graphqlUrl={graphqlUrl}
            websocketUrl={websocketUrl}
            customStyling={{
              headerText: "Converstack-GPT",
              widgetLocation: "right",
              widgetWindowWidth: "40%",
            }}
            userData={{
              email: "gptauto485@gmail.com",
              name: "gptauto",
            }}
          />
        </ConvoStackWrapper> */}
      </div>
    </>
  );
}
