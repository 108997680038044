import React, { useState, useEffect } from "react";
import {
  createStyles,
  Input,
  Select,
  Textarea,
  Radio,
  Group,
  Switch,
  Button,
} from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { Modal } from "react-responsive-modal";

const hostinfo = "https://ai-agent.smhc.tw:8000";

const UseRandomStyles = createStyles((theme) => ({
  table: {
    // backgroundColor: "rgb(55 65 81 / var(--tw-bg-opacity))",
  },
}));

const PAGE_SIZE = 10;

const TaskApp = () => {
  const [page, setPage] = useState(1);
  const [configs, setConfigs] = useState([]);
  const [records, setRecords] = useState(configs.slice(0, PAGE_SIZE));
  const [height, setHeight] = useState(window.innerHeight);

  const [open, setOpen] = useState(false);
  const [isnew, setNew] = useState(false);
  const [method1, setMethod1] = useState("");
  const [outputFormat, setOutputFormat] = useState("");
  const [state, setState] = useState({
    name: "",
    description: "",
    source: {
      type: "",
      content: "",
      path: "",
      endpoint: "",
      method: "",
      connection: { database: "", user: "", password: "", host: "" },
      table: "",
    },
    report_dest: {
      type: "",
      email: "",
      path: "",
      connection: { database: "", user: "", password: "", host: "" },
      table: "",
      endpoint: "",
      method: "",
    },
    output_format: "",
  });

  const OnOpenModal = (task) => {
    if (!task.source) {
      task.source = {
        type: "",
        path: "",
      };
    }
    if (!task.report_dest) {
      task.report_dest = {
        type: "",
        email: "",
        connection: { database: "", user: "", password: "", host: "" },
        table: "",
        endpoint: "",
        method: "",
      };
    }
    setState(task);
    setNew(false);
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  const closeIcon = (
    <svg fill="currentColor" viewBox="0 0 20 20" width={28} height={28}>
      <path
        fillRule="evenodd"
        d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  const { classes, cx } = UseRandomStyles();

  const getAllConfigs = async () => {
    try {
      const apiUrl = `${hostinfo}/api/allconfigs`;
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      const response = await fetch(apiUrl, requestOptions);
      const { configs } = await response.json();
      let configs_array = JSON.parse(configs);
      configs_array = configs_array.map((task, index) => {
        return { id: index + 1, ...task };
      });
      console.log(configs_array);
      const from = (page - 1) * PAGE_SIZE;
      const to = from + PAGE_SIZE;
      setRecords(configs_array.slice(from, to));
      setConfigs(configs_array);
    } catch (e) {
      console.log(e);
    }
  };

  const submit_data = async () => {
    try {
      const apiUrl = `${hostinfo}/api/updateconfig`;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ task: state, isnew: isnew }),
      };
      const response = await fetch(apiUrl, requestOptions);
      await response.json();
      getAllConfigs();
    } catch (e) {
      alert("Server Error occured!");
      console.log(e);
    }
    setOpen(false);
  };

  const newConfig = () => {
    setState({
      name: "",
      description: "",
      source: {
        type: "",
        path: "",
      },
      report_dest: {
        type: "",
        email: "",
        connection: { database: "", user: "", password: "", host: "" },
        table: "",
        endpoint: "",
        method: "",
      },
      output_format: "",
    });
    setNew(true);
    setOpen(true);
  };

  useEffect(() => {
    getAllConfigs();
    window.addEventListener("resize", function () {
      setHeight(window.innerHeight);
    });
  }, []);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(configs.slice(from, to));
  }, [page]);

  return (
    <div className="px-8 py-16">
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        closeIcon={closeIcon}
        style={{ borderRadius: "30px" }}
      >
        <div
          style={{
            height: "500px",
            width: "600px",
            borderRadius: "30px",
            margin: "0px 40px",
          }}
        >
          任務配置
          <hr />
          <div
            className="pt-4"
            style={{
              overflow: "scroll",
              margin: "5px 0px",
              height: "450px",
            }}
          >
            <b>Task Name</b>
            <Input
              placeholder="Task Name"
              style={{ marginBottom: "5px" }}
              value={state.name}
              onChange={(e) => setState({ ...state, name: e.target.value })}
            />

            <Textarea
              label="Description"
              placeholder="Write the description..."
              autosize
              minRows={2}
              maxRows={4}
              value={state.description}
              onChange={(e) =>
                setState({ ...state, description: e.target.value })
              }
              style={{ marginBottom: "5px" }}
            />

            <Radio.Group
              name="source"
              label="Source Config"
              description="Select the source type for reporting"
              withAsterisk
              value={state.source.type}
              onChange={(value) =>
                setState({ ...state, source: { ...state.source, type: value } })
              }
              style={{ marginBottom: "5px", marginTop: "5px" }}
            >
              <Group mt="xs">
                <Radio value="pdf" label="PDF" />
                <Radio value="csv" label="CSV" />
                <Radio value="txt" label="TXT" />
                <Radio value="" label="No source" />
              </Group>
            </Radio.Group>

            {state.source.type && (
              <React.Fragment>
                File Path
                <Input
                  placeholder="File Path"
                  style={{ marginBottom: "5px" }}
                  value={state.source.path}
                  onChange={(e) =>
                    setState({
                      ...state,
                      source: { ...state.source, path: e.target.value },
                    })
                  }
                />
              </React.Fragment>
            )}

            <Radio.Group
              name="destination"
              label="Destination Config"
              description="Select the destination type for reporting"
              withAsterisk
              value={state.report_dest.type}
              onChange={(value) =>
                setState({
                  ...state,
                  report_dest: { ...state.report_dest, type: value },
                })
              }
              style={{ marginBottom: "5px", marginTop: "5px" }}
            >
              <Group mt="xs">
                <Radio value="database" label="Database Connection" />
                <Radio value="api" label="API" />
                <Radio value="email" label="E-mail" />
                <Radio value="file" label="File Directory" />
                <Radio value="" label="No Destination" />
              </Group>
            </Radio.Group>

            {state.report_dest.type == "email" && (
              <React.Fragment>
                E-mail
                <Input
                  placeholder="Email"
                  style={{ marginBottom: "5px" }}
                  value={state.report_dest.email}
                  onChange={(e) =>
                    setState({
                      ...state,
                      report_dest: {
                        ...state.report_dest,
                        email: e.target.value,
                      },
                    })
                  }
                />
              </React.Fragment>
            )}

            {state.report_dest.type == "database" && (
              <React.Fragment>
                Database Name
                <Input
                  placeholder="Database Name"
                  style={{ marginBottom: "5px" }}
                  value={state.report_dest.connection["database"]}
                  onChange={(e) =>
                    setState({
                      ...state,
                      report_dest: {
                        ...state.report_dest,
                        connection: {
                          ...state.report_dest.connection,
                          database: e.target.value,
                        },
                      },
                    })
                  }
                />
                Database User
                <Input
                  placeholder="User"
                  style={{ marginBottom: "5px" }}
                  value={state.report_dest.connection.user}
                  onChange={(e) =>
                    setState({
                      ...state,
                      report_dest: {
                        ...state.report_dest,
                        connection: {
                          ...state.report_dest.connection,
                          user: e.target.value,
                        },
                      },
                    })
                  }
                />
                Database Password
                <Input
                  placeholder="Password"
                  style={{ marginBottom: "5px" }}
                  value={state.report_dest.connection.password}
                  onChange={(e) =>
                    setState({
                      ...state,
                      report_dest: {
                        ...state.report_dest,
                        connection: {
                          ...state.report_dest.connection,
                          password: e.target.value,
                        },
                      },
                    })
                  }
                />
                Database Host
                <Input
                  placeholder="Host"
                  style={{ marginBottom: "5px" }}
                  value={state.report_dest.connection.host}
                  onChange={(e) =>
                    setState({
                      ...state,
                      report_dest: {
                        ...state.report_dest,
                        connection: {
                          ...state.report_dest.connection,
                          host: e.target.value,
                        },
                      },
                    })
                  }
                />
                Database Table Name
                <Input
                  placeholder="Table Name"
                  style={{ marginBottom: "5px" }}
                  value={state.report_dest.table}
                  onChange={(e) =>
                    setState({
                      ...state,
                      report_dest: {
                        ...state.report_dest,
                        table: e.target.value,
                      },
                    })
                  }
                />
              </React.Fragment>
            )}

            {state.report_dest.type == "api" && (
              <React.Fragment>
                Endpoint
                <Input
                  placeholder="Endpoint"
                  style={{ marginBottom: "5px" }}
                  value={state.report_dest.endpoint}
                  onChange={(e) =>
                    setState({
                      ...state,
                      report_dest: {
                        ...state.report_dest,
                        endpoint: e.target.value,
                      },
                    })
                  }
                />
                Method
                <Select
                  placeholder="Method"
                  searchable
                  onSearchChange={setMethod1}
                  searchValue={method1}
                  value={state.report_dest.method}
                  onChange={(value) =>
                    setState({
                      ...state,
                      report_dest: { ...state.report_dest, method: value },
                    })
                  }
                  nothingFound="No options"
                  data={["GET", "POST"]}
                  style={{ marginBottom: "5px" }}
                  styles={(theme) => ({
                    item: {
                      // applies styles to selected item
                      "&[data-selected]": {
                        "&, &:hover": {
                          backgroundColor:
                            theme.colorScheme === "dark"
                              ? theme.colors.teal[9]
                              : theme.colors.teal[1],
                          color:
                            theme.colorScheme === "dark"
                              ? theme.white
                              : theme.colors.teal[9],
                        },
                      },

                      // applies styles to hovered item (with mouse or keyboard)
                      "&[data-hovered]": {},
                    },
                  })}
                />
              </React.Fragment>
            )}

            {state.report_dest.type == "file" && (
              <React.Fragment>
                File Path
                <Input
                  placeholder="File Path"
                  style={{ marginBottom: "5px" }}
                  value={state.report_dest.path}
                  onChange={(e) =>
                    setState({
                      ...state,
                      report_dest: {
                        ...state.report_dest,
                        path: e.target.value,
                      },
                    })
                  }
                />
              </React.Fragment>
            )}

            {state.report_dest.type != "" && (
              <React.Fragment>
                <b>Output Format</b>
                <Select
                  placeholder="Output_format"
                  searchable
                  onSearchChange={setOutputFormat}
                  searchValue={outputFormat}
                  value={state.output_format}
                  onChange={(value) =>
                    setState({ ...state, output_format: value })
                  }
                  nothingFound="No options"
                  data={["", "pdf", "docx", "txt"]}
                  style={{ marginBottom: "5px" }}
                  styles={(theme) => ({
                    item: {
                      // applies styles to selected item
                      "&[data-selected]": {
                        "&, &:hover": {
                          backgroundColor:
                            theme.colorScheme === "dark"
                              ? theme.colors.teal[9]
                              : theme.colors.teal[1],
                          color:
                            theme.colorScheme === "dark"
                              ? theme.white
                              : theme.colors.teal[9],
                        },
                      },

                      // applies styles to hovered item (with mouse or keyboard)
                      "&[data-hovered]": {},
                    },
                  })}
                />
              </React.Fragment>
            )}

            <Button
              style={{ marginBottom: "5px", float: "right" }}
              type="submit"
              onClick={submit_data}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      <div
        style={{
          marginBottom: "5px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div></div>
        <Button onClick={newConfig}>+New</Button>
      </div>
      <DataTable
        withBorder
        borderRadius="md"
        borderColor="#ccc"
        withColumnBorders
        striped
        highlightOnHover
        className={classes.table}
        height={height - 110}
        // provide data
        records={records}
        columns={[
          {
            accessor: "id",
            title: "No",
            textAlignment: "center",
            cellsClassName: classes.table,
            titleClassName: classes.table,
          },
          {
            accessor: "name",
            title: "Function Name",
            textAlignment: "center",
            cellsClassName: classes.table,
            titleClassName: classes.table,
          },
          {
            accessor: "description",
            title: "Description",
            textAlignment: "center",
            cellsClassName: classes.table,
            titleClassName: classes.table,
          },
        ]}
        onRowClick={(config) => OnOpenModal(config)}
        totalRecords={configs.length}
        recordsPerPage={PAGE_SIZE}
        page={page}
        onPageChange={(p) => setPage(p)}
        paginationSize="sm"
        paginationColor="grape"
        loadingText="加载中..."
      />
    </div>
  );
};

export default TaskApp;
