import React from "react";
import { useNavigate } from "react-router-dom";

function Sidebar() {
  let history = useNavigate();

  const logout = () => {
    localStorage.removeItem("userData");
    history("/login");
  };

  return (
    <div className="absolute sm:relative bg-gray-600 shadow md:h-full flex-col justify-between hidden sm:flex ">
      <div className="px-1">
        <input
          className="text-emerald-500 background-transparent font-bold uppercase px-8 py-3 focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 h-16"
          type="button"
          style={{
            marginBottom: "40px",
            marginTop: "20px",
            backgroundColor: "transparent",
          }}
          value="GenAI-BPM控制台"
        />

        <button
          className="w-full flex justify-center items-center h-16 cursor-pointer"
          style={{
            justifyContent: "center",
            color: "#fff",
            fontSize: "14px",
            backgroundColor: "transparent",
          }}
          onClick={() => history("/")}
        >
          對話式機器人
        </button>

        <button
          className="w-full flex items-center justify-center h-16 cursor-pointer"
          style={{
            justifyContent: "center",
            color: "#fff",
            fontSize: "14px",
            backgroundColor: "transparent",
          }}
          onClick={() => history("/config")}
        >
          對話式端口配置
        </button>

        <button
          className="w-full flex items-center justify-center h-16 cursor-pointer"
          style={{
            justifyContent: "center",
            color: "#fff",
            fontSize: "14px",
            backgroundColor: "transparent",
          }}
          onClick={() => history("/taskconfig")}
        >
          自動化任務配置
        </button>

        <button
          className="px-2 py-1 bg-white rounded flex items-center justify-center mx-auto mt-10 hover:bg-gray-200"
          onClick={logout}
        >
          登出
        </button>
      </div>
      <div className="px-2 border-t border-gray-700">
        <div className="w-full flex items-center justify-center p-2"></div>
      </div>
    </div>
  );
}

export default Sidebar;
