import React from "react";
import { ulid } from "ulid";

export default function Messages({ messages }) {
  let colorStyle = "";
  return messages.map((message, key) => {
    if (message["username"] == "CB")
      colorStyle =
        "w-[32px] h-[32px] grid place-content-center text-[18px] bg-[orange] text-gray-100 rounded-full";
    else
      colorStyle =
        "w-[32px] h-[32px] grid place-content-center text-[18px] bg-gray-700 text-gray-100 rounded-full";
    return (
      <div
        key={ulid()}
        className="relative w-[834px] mx-auto text-gray-100 text-base"
        id="msg-demo"
      >
        <div className="grid w-full text-base gap-y-6 p-4">
          <div className="flex gap-[28px]">
            <div className={colorStyle}>{message["username"]}</div>
            <div className="text-gray-800">
              <div>
                {message["sourcefile"] && (
                  <React.Fragment>
                    <label>
                      原始檔 :{" "}
                      <a
                        href={
                          "https://ai-agent.smhc.tw:8000/" + message["sourcefile"]
                        }
                      >
                        {message["sourcefile"]}
                      </a>
                    </label>
                  </React.Fragment>
                )}
              </div>
              <div>
                {message["chatdata"].split("\n").map((i, key) => {
                  return <div key={key}>{i}</div>;
                })}
              </div>
              <div>
                {message["attachedfile"] && (
                  <a
                    href={
                      "https://ai-agent.smhc.tw:8000/" + message["attachedfile"]
                    }
                  >
                    <br />
                    <img
                      width="36"
                      height="36"
                      src={`/img/${message["attachedfile"]
                        .split(".")
                        .pop()}.png`}
                      alt="icon"
                    />{" "}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
}
