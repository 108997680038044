import React from "react";
import { useEffect, useState } from "react";

import Command from "./chat/command";
import Messages from "./chat/message";
import Chat_spinner from "./landing/chat_spinner";

export default function Chatbot() {
  let [messages, setMessages] = useState([]);
  let [running, setRunning] = useState(false);
  let messageRef = null;

  useEffect(() => {
    messageRef.scrollTop = messageRef.scrollHeight;
  }, [messages]);

  const sendMessage = async (message) => {
    setRunning(true);
    let apiUrl = "";

    apiUrl = "https://ai-agent.smhc.tw:8000/api/query";

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ query: message }),
    };

    messages = [...messages, { username: "Us", chatdata: message }];
    setMessages(messages);

    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      setMessages([
        ...messages,
        {
          username: "Cb",
          chatdata: data.message,
          sourcefile: data.sourcefile,
          attachedfile: data.attachedfile,
        },
      ]);
    } catch (e) {
      console.log(e.message);
      setMessages([...messages, { username: "Cb", chatdata: "" }]);
    }

    setRunning(false);
  };

  return (
    <React.Fragment>
      <div
        className="w-full h-full border-2 border-none px-8"
        ref={(el) => {
          messageRef = el;
        }}
        style={{
          maxHeight: "calc(100vh - 130px)",
          overflowY: "scroll",
          scrollbarWidth: "thin",
        }}
      >
        <Messages messages={messages} />
        {running ? <Chat_spinner /> : ""}
      </div>
      <Command onHandleSubmit={sendMessage} />
    </React.Fragment>
  );
}
