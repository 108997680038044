import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Login() {
  const [data, setData] = useState({
    userName: "",
    password: "",
  });
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const submit = (e) => {
    e.preventDefault();
    if (data.userName === "admin" && data.password === "GenAIBPM2023") {
      const userData = JSON.stringify(data);
      localStorage.setItem("userData", userData);
      navigate("/");
    } else {
      setErrMsg("登入失敗");
    }
  };

  return (
    <>
      <div className="fixed top-0 left-0 bg-[#282c34] w-full h-full">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-2/3">
          <div className="flex flex-col md:flex-row justify-center items-stretch max-w-full">
            <div className="grow hidden md:block md:w-[370px] text-[#1d020f] bg-[#fbe3d4]">
              <div className="flex flex-col justify-center items-center h-full">
                <div className="w-[135px] h-[135px] mb-4">
                  <img
                    src="./公司Logo(無標題).png"
                    alt="艾立斯科技logo"
                    className="w-full h-full"
                  />
                </div>
                <h2 className="text-[26px] font-bold mb-6">GenAI-BPM控制台</h2>
              </div>
            </div>
            <div className="grow md:w-[370px] text-[#1d020f] bg-white">
              <div className="flex flex-col justify-center p-10">
                <h2 className="text-3xl font-bold mb-3">登入</h2>
                <h3 className="text-lg font-semibold mb-3">
                  觀迎回來，請登入您的帳號
                </h3>
                <div
                  className={`bg-red-200 text-red-800 font-bold py-3 pl-2 mb-3 ${
                    errMsg ? "block" : "hidden"
                  }`}
                >
                  {errMsg}
                </div>
                <form>
                  <div className="flex flex-col mb-2 w-[250px] md:w-full">
                    <label
                      htmlFor="userName"
                      className="text-lg font-semibold mb-1"
                    >
                      帳號
                    </label>
                    <input
                      id="userName"
                      name="userName"
                      type="text"
                      placeholder="請輸入帳號"
                      className="text-[#363636] text-base md:text-[17px] py-[6px] pl-2 border-2 border-gray-400 focus:border-[#228be6] focus:outline-none focus:ring-0"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex flex-col mb-5 w-[250px] md:w-full">
                    <label
                      htmlFor="password"
                      className="text-lg font-semibold mb-1"
                    >
                      密碼
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      placeholder="請輸入密碼"
                      className="text-[#363636] text-base md:text-[17px] py-[6px] pl-2 border-2 border-gray-400 focus:border-[#228be6] focus:outline-none focus:ring-0"
                      onChange={handleChange}
                    />
                  </div>
                  <button
                    className="flex items-center justify-center w-full bg-[#228be6] py-2 px-4 text-lg font-semibold text-white rounded hover:bg-[#1c7ed6]"
                    onClick={submit}
                  >
                    登入
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="fixed top-0 left-0 bg-[#282c34] w-full h-full">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-2/3">
          <div className="flex justify-center items-stretch max-w-full">
            <div className="grow md:w-[370px] text-[#1d020f] bg-white">
              <div className="flex flex-col justify-center p-10">
                <h2 className="text-3xl font-bold mb-3">登入</h2>
                <h3 className="text-lg font-semibold mb-3">
                  觀迎回來，請登入您的帳號
                </h3>
                <div
                  className={`bg-red-200 text-red-800 font-bold py-3 pl-2 mb-3 ${
                    errMsg ? "block" : "hidden"
                  }`}
                >
                  {errMsg}
                </div>
                <form>
                  <div className="flex flex-col mb-2 w-[250px] md:w-full">
                    <label
                      htmlFor="userName"
                      className="text-lg font-semibold mb-1"
                    >
                      帳號
                    </label>
                    <input
                      id="userName"
                      name="userName"
                      type="text"
                      placeholder="請輸入帳號"
                      className="text-[#363636] text-base md:text-[17px] py-[6px] pl-2 border-2 border-gray-400 focus:border-[#228be6] focus:outline-none focus:ring-0"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex flex-col mb-5 w-[250px] md:w-full">
                    <label
                      htmlFor="password"
                      className="text-lg font-semibold mb-1"
                    >
                      密碼
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      placeholder="請輸入密碼"
                      className="text-[#363636] text-base md:text-[17px] py-[6px] pl-2 border-2 border-gray-400 focus:border-[#228be6] focus:outline-none focus:ring-0"
                      onChange={handleChange}
                    />
                  </div>
                  <button
                    className="flex items-center justify-center w-full bg-[#228be6] py-2 px-4 text-lg font-semibold text-white rounded hover:bg-[#1c7ed6]"
                    onClick={submit}
                  >
                    登入
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Login;
